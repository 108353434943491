<template>
  <div class="compony">
    <div class="bigCenter">
      <video autoplay muted loop>
        <source src="/video/bg.mp4" type="video/mp4" />
        <!-- <source
          src="http://fb.video.weibocdn.com/o8/tj8Az6uzlx083mUwle1q01041201Dh1g0E018.mp4?label=mp4_1080p&template=1872x968.25.0&media_id=4872253431808071&tp=8x8A3El:YTkl0eM8&us=0&ori=1&bf=4&ot=h&ps=3lckmu&uid=6BdACi&ab=9298-g4,8224-g0,7397-g1,3601-g32,8143-g0,3568-g1,8013-g0,7598-g0&Expires=1677122979&ssig=uH7MtmsZNE&KID=unistore,video"
          type="video/mp4"
        /> -->
        您的浏览器不支持Video标签。
      </video>
      <!-- <div class="introduce">
        <div
          id="typed"
          class="
            animate__animated animate__bounce animate__infinite animate__slow
          "
        >
          一次<span>客户</span> 一生<span>朋友</span> <span>成交</span>只是开始
          <span>服务</span>永无止境
        </div>
        <p>艾拓思·信未来</p>
      </div> -->
    </div>

    <div class="welcomBox">
      <H2>欢迎来到 艾拓思 官方网站!</H2>
      <p class="describe">
        山西艾拓思科技有限公司成立于2016,注册资本为1000万元人民币,企业地址位于太原市小店区通达街,所属行业为科技推广和应用服务业,经营范围包含：矿用设备、计算机软硬件、电气产品、电子产品的研发、生产、安装、销售、维修及技术服务(生产限分支机构);计算机系统集成;网络工程的设计、安装、调试;计算机软件开发;工业自动化系统、物联网、智能化矿山系统研发;安全技术防范系统的设计、安装、监理、运营;计算机及耗材、不锈钢制品、煤炭、化工产品(不含危险化学品)、普通机械设备、建筑材料、仪器仪表、装潢材料、电线电缆、橡胶制品、桶装润滑油、保温材料、日用百货、金属材料(不含贵稀金属)、空调制泠设备、卫生洁具、服装鞋帽、文化办公用品、劳动防护用品、水暖器材、
        监控设备、通讯设备、建筑材料、水处理设备、五金交电的销售;建设工程(电力工程;环保工程;亮化工程;灯光、音响及舞台设计、施工);工矿机械设备的销售及维修;交通设施、水利设施、照明设施、节能产品的销售、安装、维修及技术服务;矿用高分子材料的研发、销售;货物或技术进出口。
      </p>
    </div>
    <div class="fzlc">
      <img src="/images/fzlc.png" style="margin-top: 30px" alt="" />
      <el-timeline class="listBox">
        <el-timeline-item timestamp="2016年/6月/30日" placement="top">
          <el-card>
            <h6>山西艾拓思科技有限公司公司成立</h6>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2016年/10月" placement="top">
          <el-card>
            <h6>成立运维团队,开始服务于矿山信息化及安全监测</h6>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2017年/8月" placement="top">
          <el-card>
            <h6>正式启动矿山安全避险六大系统项目</h6>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2018年/9月" placement="top">
          <el-card>
            <h6>尾矿库在线监测项目启动</h6>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2019年/6月" placement="top">
          <el-card>
            <h6>公司成立研发部,启动智慧矿山、绿色矿山建设 边坡监测项目</h6>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2020年/5月" placement="top">
          <el-card>
            <h6>成立内蒙分公司,启动5G及万兆工业环网项目</h6>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2021年/6月" placement="top">
          <el-card>
            <h6>启动实施智能化矿山项目,研发智能化综合管控平台</h6>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2022年/4月" placement="top">
          <el-card>
            <h6>启动实施智能化矿山项目,研发智能化综合管控平台</h6>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
    <img src="/images/cpjs.png" style="margin-top: 30px" alt="" />
    <div class="swiperBox">
      <div
        style="--swiper-navigation-color: #fff; --swiper-pagination-color: #fff"
        class="swiper mySwiper2"
      >
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="/images/nature1.png" />
          </div>
          <div class="swiper-slide">
            <img src="/images/nature2.png" />
          </div>
          <div class="swiper-slide">
            <img src="/images/nature3.png" />
          </div>
          <div class="swiper-slide">
            <img src="/images/nature3.png" />
          </div>
          <div class="swiper-slide">
            <img src="/images/nature4.png" />
          </div>
          <div class="swiper-slide">
            <img src="/images/nature5.png" />
          </div>
          <div class="swiper-slide">
            <img src="/images/nature6.png" />
          </div>
          <div class="swiper-slide">
            <img src="/images/nature7.png" />
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
      <div thumbsSlider="" class="swiper mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="/images/nature1.png" />
          </div>

          <div class="swiper-slide">
            <img src="/images/nature2.png" />
          </div>
          <div class="swiper-slide">
            <img src="/images/nature3.png" />
          </div>
          <div class="swiper-slide">
            <img src="/images/nature4.png" />
          </div>
          <div class="swiper-slide">
            <img src="/images/nature5.png" />
          </div>
          <div class="swiper-slide">
            <img src="/images/nature6.png" />
          </div>
          <div class="swiper-slide">
            <img src="/images/nature7.png" />
          </div>
        </div>
      </div>
    </div>

    <div class="cooperation">
      <img src="/images/hzhb.png" alt="" />
      <ul>
        <li>
          <img src="/images/jiaomei.png" class="hvr-bounce-in" alt="" />
        </li>
        <li>
          <img src="/images/yidong.png" class="hvr-bounce-in" alt="" />
        </li>
        <li>
          <img src="/images/huangjin.png" class="hvr-bounce-in" alt="" />
        </li>
        <li>
          <img src="/images/shenxinfu.png" class="hvr-bounce-in" alt="" />
        </li>
        <li>
          <img src="/images/aliyun.png" class="hvr-bounce-in" alt="" />
        </li>
        <li>
          <img src="/images/zijinqiao.png" class="hvr-bounce-in" alt="" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  mounted() {
    var swiper = new Swiper(".mySwiper", {
      loop: true,
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper(".mySwiper2", {
      loop: true,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });
  },
};
</script>

<style lang="less">
.swiperBox {
  width: 80%;
  margin: 0 auto;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bigCenter {
  position: relative;

  video {
    width: 100%;
  }

  .introduce {
    position: absolute;
    color: #fff;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 80%;
    height: 200px;
    margin: auto;

    span {
      color: #0e96dc;
    }

    div {
      font-size: 24px;
      letter-spacing: 6px;
    }

    p {
      line-height: 60px;
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.welcomBox {
  background-color: #f4f4f4;
  padding: 40px;

  h2 {
    line-height: 100px;
  }

  .describe {
    font-size: 16px;
    color: #777;
    width: 80%;
    text-align: center;
    margin: 0 auto 50px auto;
    text-indent: 30px;
    text-align: left;
  }
}

.cooperation {
  width: 60%;
  text-align: center;
  margin: 40px auto;

  ul {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    flex-wrap: wrap;

    img {
      width: 100px;
    }
  }
}

.listBox {
  width: 40%;
  margin: 0 auto !important;
}

.el-timeline-item__timestamp {
  color: rgb(0, 110, 255) !important;
  font-size: 16px !important;
}
</style>
