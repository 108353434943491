import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/PersonnelPositioning',
    name: 'PersonnelPositioning',
    component: () => import('../views/PersonnelPositioning.vue')
  },
  {
    path: '/SmartMine',
    name: 'SmartMine',
    component: () => import('../views/SmartMine.vue')
  },
  {
    path: '/DoublePrevention',
    name: 'DoublePrevention',
    component: () => import('../views/DoublePrevention.vue')
  },
  {
    path: '/TailingsPond',
    name: 'TailingsPond',
    component: () => import('../views/TailingsPond.vue')
  },
  {
    path: '/WaterView',
    name: 'WaterView',
    component: () => import('../views/WaterView.vue')
  },
  {
    path: '/CoalBunker',
    name: 'CoalBunker',
    component: () => import('../views/CoalBunker.vue')
  },
  {
    path: '/sideSlope',
    name: 'sideSlope',
    component: () => import('../views/sideSlope.vue')
  },
  {
    path: '/radioBroadcast',
    name: 'radioBroadcast',
    component: () => import('../views/radioBroadcast.vue')
  },
  {
    path: '/ControlPlatform',
    name: 'ControlPlatform',
    component: () => import('../views/ControlPlatform.vue')
  },
  {
    path: '/traffic',
    name: 'traffic',
    component: () => import('../views/traffic.vue')
  },
  {
    path: '/monitor',
    name: 'monitor',
    component: () => import('../views/monitor.vue')
  },
  {
    path: '/Ethernet',
    name: 'Ethernet',
    component: () => import('../views/Ethernet.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
