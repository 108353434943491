<template>
  <div id="app">
    <el-container>
      <el-header class="headerBox">
        <TopNav />
      </el-header>
      <el-main> <router-view /></el-main>
      <el-footer>
        <div class="footer">
          <div class="identification">
            <div class="">
              <img alt="加密传输电子标识" src="/images/ssl.png" />
            </div>
            <div class="">
              <img alt="工信网备电子标识" src="/images/icp-small.png" />
            </div>
            <div class="">
              <img alt="公安网安电子标识" src="/images/police-small.png" />
            </div>
            <div class="">
              <img alt="工商网监电子标识" src="/images/12315-small.png" />
            </div>
          </div>
          <ul>
            <li>咨询热线：0351-8282236</li>
            <li>地址：山西省太原市小店区都乐街万豪新源地综合楼8层</li>
            <li>邮箱： gengxiaowei001@163.com</li>
            <li>Copyright © 2016-2030 Bitmoe Inc. 保留所有权利</li>
            <li><a href="https://beian.miit.gov.cn" target="_blank">晋ICP备2020009478号-1</a></li>
          </ul>
        </div>
      </el-footer>
    </el-container>

    <el-dialog close-on-click-modal="false" title="手机验证" :visible.sync="dialogVisible" width="400px">
      <p>输入手机号可以获取更多详细介绍资料</p>
      <el-form :model="form" ref="form" :rules="rules" label-width="80px" :inline="false" size="normal">
        <el-form-item label="手机号" prop="iphone">
          <el-input v-model="form.iphone"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input v-model="form.code">
            <el-button slot="append" @click="getCode"> <span v-show="show">{{ time }}</span> <span
                v-show="!show">获取验证码</span> </el-button>
          </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// @ is an alias to /src
import TopNav from "@/components/TopNav.vue";
export default {
  components: {
    TopNav,
  },
  data() {
    return {
      form: {
        iphone: "",
        code: "",
      },
      rules: {
        iphone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^1[34578]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          }],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      dialogVisible: false,
      time: 60,
      show: false,
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$message({
            message: "验证成功",
            type: "success",
          });
          this.dialogVisible = false
        }
      });
    },
    getCode() {
      this.show = false
      if (this.form.iphone.length == 11) {
        this.show = true
        this.time = 60
        var time = setInterval(() => {
          if (this.time == 0) {
            clearInterval(time)
            this.time = 60
          } else {
            this.time--
          }
        }, 1000)
        this.$message({
          message: "验证码已发送",
          type: "success",
        });
      } else {
        this.$message({
          message: "请输入正确的手机号",
          type: "error",
        });
      }
    }
  },
};
</script>


<style lang="less">
.headerBox {
  height: 61px;
  border-bottom: 1px solid #8e858547;
}

#app {
  font-family: "Microsoft YaHei", "Microsoft YaHei UI";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

a {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.el-header {
  padding: 0px !important;
}

.el-main {
  padding: 0px !important;
}

.el-footer {
  padding: 0px !important;
}

.footer {
  background-color: #2c3e50;
  text-align: center;
  color: #fff;
  padding: 40px;

  li {
    font-size: 14px;
  }

  .identification {
    display: flex;
    margin: 0 auto;
    display: flex;
    margin: 0 auto;
    width: 190px;
    justify-content: space-between;
    margin-bottom: 20px;

    a {
      display: block;
      font-size: 12px;
    }
  }
}
</style>
