<template>
  <div class="navBar">
    <b-navbar
      toggleable="lg"
      type="light"
      :sticky="true"
      fixed="top"
      variant="light"
    >
      <b-navbar-brand to="/">
        <img
          src="/images/logo.png"
          alt=""
          style="height: 30px; margin-left: 10px"
        />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav fill>
          <b-nav-item to="/" :active="getPath('/')">首页</b-nav-item>
          <b-nav-item
            to="/PersonnelPositioning"
            :active="getPath('/PersonnelPositioning')"
            >人员定位</b-nav-item
          >
          <b-nav-item
            to="/DoublePrevention"
            :active="getPath('/DoublePrevention')"
            >双重预防</b-nav-item
          >
          <b-nav-item to="/SmartMine" :active="getPath('/SmartMine')"
            >智慧矿山</b-nav-item
          >
          <b-nav-item to="/TailingsPond" :active="getPath('/TailingsPond')"
            >尾矿库在线监测</b-nav-item
          >
          <b-nav-item to="/WaterView" :active="getPath('/WaterView')"
            >智慧水务</b-nav-item
          >
          <b-nav-item to="/CoalBunker" :active="getPath('/CoalBunker')"
            >煤仓煤位监测</b-nav-item
          >
          <b-nav-item to="/sideSlope" :active="getPath('/sideSlope')"
            >露天矿山监测</b-nav-item
          >
          <b-nav-item-dropdown text="产品相关" right>
            <b-dropdown-item
              to="/radioBroadcast"
              :active="getPath('/radioBroadcast')"
              >矿用广播系统</b-dropdown-item
            >
            <b-dropdown-item
              to="/ControlPlatform"
              :active="getPath('/ControlPlatform')"
              >矿山综合管控平台</b-dropdown-item
            >
            <b-dropdown-item to="/traffic" :active="getPath('/traffic')"
              >矿山智能交通系统</b-dropdown-item
            >
            <b-dropdown-item to="/monitor" :active="getPath('/monitor')"
              >监测监控</b-dropdown-item
            >
            <b-dropdown-item to="/Ethernet" :active="getPath('/Ethernet')"
              >5G及万兆工业以太 环网、超融合</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item right to="/about" :active="getPath('/about')"
            >关于我们</b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "TopNav",
  data() {
    return {
      isShow: true,
      activeIndex: "/",
      isClick: true,
    };
  },
  mounted() {
    this.getWindowInfo();
    window.addEventListener("resize", this.getWindowInfo);
  },
  methods: {
    getPath(url) {
      if (this.$route.path == url) {
        return true;
      } else {
        return false;
      }
    },
    getWindowInfo() {
      if (window.innerWidth < 1800) {
        this.isShow = true;
      } else {
        // 大屏
        this.isShow = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
#nav-collapse {
  justify-content: space-around;
}
</style>

<style>
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: #1298dc !important;
}
</style>
